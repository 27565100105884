import { createSlice } from "@reduxjs/toolkit";

export const videoSlice = createSlice({
  name: "videos",
  initialState: {
    regionId: sessionStorage.getItem("regionId") || null,
    videos: [],
    loading: true,
    pageToken: null,
    backToHome: false,
    regions: [],
  },
  reducers: {
    updateRegionId: (state, action) => {
      sessionStorage.setItem("regionId", action.payload);
      state.regionId = action.payload;
    },
    getVideos: (state, action) => {},
    getRegionVideos: (state, action) => {},
    startTracking: (state) => {},
    updateBackToHome: (state, action) => {
      state.backToHome = action.payload;
    },
    setVideos: (state, action) => {
      if (!action.payload || action.payload.length === 0) {
        state.videos = [];
      } else {
        state.videos.push(...action.payload);
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPageToken: (state, action) => {
      state.pageToken = action.payload;
    },
    getRegions: (state, action) => {},
    updateRegions: (state, action) => {
      state.regions = action.payload;
    },
  },
});

export const {
  setVideos,
  setLoading,
  setPageToken,
  getVideos,
  getRegionVideos,
  updateBackToHome,
  updateRegionId,
  startTracking,
  getRegions,
  updateRegions
} = videoSlice.actions;

export default videoSlice.reducer;
