import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./containers/App/App";
import Home from "./containers/Home/home";
import ChAnalytics from "./containers/Channel/channelAnalytics";
import Predictions from "./containers/Channel/Predictions";
import { connect } from "react-redux";
import VideosRegion from "./containers/videos/videosRegion";
import TopicsChannels from "./containers/topicChannels/topicsChannels";

const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <Router basename="">
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path=":channelTitle/analytics" element={<ChAnalytics />} />
          <Route path=":channelTitle/videos" element={<ChAnalytics />} />
          <Route path=":channelTitle/about" element={<ChAnalytics />} />
          <Route path=":channelTitle/similar" element={<ChAnalytics />} />
          <Route path=":channelTitle/predictions" element={<ChAnalytics />} />
          <Route
            path=":channelTitle/videos/:videoId"
            element={<ChAnalytics />}
          />
          <Route path=":region/regionalVideos" element={<VideosRegion />} />
          <Route path="topicChannels" element={<TopicsChannels />} />
        </Route>
      </Routes>
    </Router>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(PublicRoutes);
