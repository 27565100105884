import React, { useState, useEffect, useRef } from "react";
import { PieChart, Pie, Cell } from "recharts";
import ReactECharts from "echarts-for-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "antd";
import { SlLike } from "react-icons/sl";
import { IoEyeOutline } from "react-icons/io5";
import { connect } from "react-redux";
import {
  getVideoStats,
  getVideosData,
} from "../../redux/videoDetail/videoDetailState";
import "./style.css";
import { Helmet } from "react-helmet";

const Analytics = ({
  recentVideo,
  videoStats,
  dailyData,
  monthlyData,
  getVideoStats,
  channelEngRates,
  getVideosData,
  lightMode,
  channelDetail,
}) => {
  const [viewDataPoints, setViewDataPoints] = useState([]);
  const [subDataPoints, setSubDataPoints] = useState([]);
  const chartRef = useRef(null);
  const chartRef2 = useRef(null);
  const [hoveredData2, setHoveredData2] = useState(null);
  const [hoveredData, setHoveredData] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [months, setMonths] = useState([]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const calculateGainLoss = (data) => {
    const calculatedData = data.map((item, index) => {
      const previousItem = index > 0 ? data[index - 1] : null;
      const subscribersGainLoss = previousItem
        ? item.subscribers - previousItem.subscribers
        : "";
      const viewsGainLoss = previousItem ? item.views - previousItem.views : "";
      return {
        key: index,
        date: formatDate(item.date),
        views: item.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        subscribers: item.subscribers
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        subscribersGainLoss:
          subscribersGainLoss > 0
            ? `+${subscribersGainLoss
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : subscribersGainLoss
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        viewsGainLoss:
          viewsGainLoss > 0
            ? `+${viewsGainLoss
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : viewsGainLoss.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      };
    });
    return calculatedData;
  };
  const calculateTotalSubscribersGained = (dailyValues) => {
    // Ensure there's enough data to calculate
    if (dailyValues.length < 2) {
      // console.log(
      //   "Not enough data available to calculate total subscribers gained."
      // );
      return 0;
    }

    // Get the subscriber count at the start
    let subscribersAtStart = dailyValues[0].subscribers;
    // Get the subscriber count at the end
    let subscribersAtEnd = dailyValues[dailyValues.length - 1].subscribers;

    // Calculate subscribers gained
    let subscribersGained = subscribersAtEnd - subscribersAtStart;

    return subscribersGained;
  };
  const calculateTotalViewsGained = (dailyValues) => {
    // Ensure there's enough data to calculate
    if (dailyValues.length < 2) {
      // console.log("Not enough data available to calculate total views gained.");
      return 0;
    }

    // Get the subscriber count at the start
    let viewsAtStart = dailyValues[0].views;
    // Get the subscriber count at the end
    let viewsAtEnd = dailyValues[dailyValues.length - 1].views;

    // Calculate views gained
    let viewsGained = viewsAtEnd - viewsAtStart;

    return viewsGained;
  };
  const formatNumber = (value) => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(1) + "B";
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + "M";
    } else {
      return value;
    }
  };
  const handleSeeMore = (video) => {
    sessionStorage.setItem("selectedVideo", video.snippet.resourceId.videoId);
    const newPath = location.pathname.includes("/analytics")
      ? location.pathname.replace("/analytics", "") +
        `/videos/${video.snippet.resourceId.videoId}`
      : `${location.pathname}/videos/${video.snippet.resourceId.videoId}`;
    navigate(newPath);
  };
  const getOption = (dataType) => {
    const dataPoints = dataType === "view" ? viewDataPoints : subDataPoints;
    const largestNumber = Math.max(...dataPoints);
    const roundedNumber = Math.ceil(largestNumber / 1000000000) * 1000000000;
    const yaxisNum = roundedNumber + 1000000000;
    return {
      grid: {
        left: "10%",
        right: "10%",
        bottom: "10%",
        top: "10%",
      },
      xAxis: {
        type: "category",
        data: months.slice(0, 12),
        axisLine: {
          lineStyle: {
            color: `${lightMode ? "black" : "#fff"}`,
          },
        },
      },
      yAxis: {
        type: "value",
        max: yaxisNum,
        axisLabel: {
          formatter: (value) => formatNumber(value),
          color: `${lightMode ? "black" : "#fff"}`,
        },
        axisLine: {
          lineStyle: {
            color: "#ccc",
          },
        },
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) =>
          params[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      },
      series: [
        {
          type: "line",
          data: dataPoints.slice(0, 12),
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: `${
                    lightMode
                      ? "rgba(220, 38, 38, 0.7)"
                      : "rgba(255, 255, 255, 0.8)"
                  }`,
                },
                {
                  offset: 1,
                  color: `${lightMode ? "white" : "rgba(0, 0, 0, 0.2)"}`,
                },
              ],
            },
          },
          lineStyle: {
            color: `${lightMode ? "#dc2626" : "white"}`,
          },
          symbol: "circle",
          color: "#FC0707",
          symbolSize: 8,
        },
      ],
      custom: (event) => {},
    };
  };

  const handleMouseOver = (event) => {
    setHoveredData(event.value); // Example: Update state with hovered data
  };

  const handleMouseOut = () => {
    setHoveredData(null); // Reset hoveredData to null when user stops hovering over the chart
  };

  const handleMouseOver2 = (event) => {
    setHoveredData2(event.data);
  };

  const handleMouseOut2 = () => {
    setHoveredData2(null);
  };
  // Calculate gain/loss when dailyData changes
  useEffect(() => {
    const calculatedData = calculateGainLoss(dailyData);
    setDataSource(calculatedData);
  }, [dailyData]);
  useEffect(() => {
    const newData = [
      {
        name: "likes",
        value: parseInt(videoStats.likeCount, 10),
        color: "#FC0707",
      }, // Red-500
      {
        name: "comments",
        value: parseInt(videoStats.commentCount, 10),
        color: "#4ade80",
      }, // Blue-600
    ];
    setData(newData);
  }, [videoStats]);
  useEffect(() => {
    getVideoStats();
    getVideosData();
  }, []);
  useEffect(() => {
    const chartInstance = chartRef.current.getEchartsInstance();
    const chartInstance2 = chartRef2.current.getEchartsInstance();
    if (chartInstance) {
      chartInstance.on("mouseover", handleMouseOver);
      chartInstance.on("mouseout", handleMouseOut); // Add mouseout event listener
    }

    if (chartInstance2) {
      chartInstance2.on("mouseover", handleMouseOver2);
      chartInstance2.on("mouseout", handleMouseOut2);
    }
    return () => {
      if (chartInstance) {
        chartInstance.off("mouseover", handleMouseOver);
        chartInstance.off("mouseout", handleMouseOut); // Remove mouseout event listener
      }
      if (chartInstance2) {
        chartInstance2.off("mouseover", handleMouseOver2);
        chartInstance2.off("mouseout", handleMouseOut2);
      }
    };
  }, []);
  useEffect(() => {
    const updatedViewDataPoints = monthlyData.map((item) => item.views);
    const updatedSubDataPoints = monthlyData.map((item) => item.subscribers);
    setViewDataPoints(updatedViewDataPoints);
    setSubDataPoints(updatedSubDataPoints);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const updatedMonths = monthlyData.map((item) => {
      const [month] = item.monthYear.split("/");
      const monthIndex = parseInt(month, 10) - 1;
      return monthNames[monthIndex];
    });
    setMonths(updatedMonths);
  }, [monthlyData]);

  const formatRevenue = (revenue) => {
    if (revenue >= 1000000000) {
      return (revenue / 1000000000).toFixed(2) + "B";
    } else if (revenue >= 1000000) {
      return (revenue / 1000000).toFixed(2) + "M";
    } else if (revenue >= 1000) {
      return (revenue / 1000).toFixed(2) + "K";
    } else {
      return revenue.toFixed(2);
    }
  };

  const calculateRevenueRange = (views) => {
    const viewsNumber = parseFloat(views.replace(/[+,]/g, ""));

    // Define the multiplier range
    const lowerMultiplier = 1.36;
    const upperMultiplier = 3.4;

    // Calculate lower and upper bounds
    const lowerBound = formatRevenue((lowerMultiplier * viewsNumber) / 1000);
    const upperBound = formatRevenue((upperMultiplier * viewsNumber) / 1000);

    return `$${lowerBound} - $${upperBound}`;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Subscribers",
      children: [
        {
          title: "Gain/Loss",
          dataIndex: "subscribersGainLoss",
          key: "subscribersGainLoss",
        },
        {
          title: "Total",
          dataIndex: "subscribers",
          key: "subscribersTotal",
        },
      ],
    },
    {
      title: "Views",
      children: [
        {
          title: "Gain/Loss",
          dataIndex: "viewsGainLoss",
          key: "viewsGainLoss",
        },
        {
          title: "Total",
          dataIndex: "views",
          key: "viewsTotal",
        },
      ],
    },
    {
      title: "Revenue Range",
      dataIndex: "revenueRange",
      key: "revenueRange",
      render: (text, record) => {
        if (record.viewsGainLoss) {
          return calculateRevenueRange(record.viewsGainLoss);
        }
      },
    },
  ];

  return (
    <div>
      {channelDetail.snippet && (
        <Helmet>
          <title>
            Channel Analytics | {channelDetail.snippet.customUrl} Statistics &
            Insights
          </title>
        </Helmet>
      )}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/4 sm:mr-2">
          {/* recent video div */}
          {recentVideo && recentVideo.snippet && (
            <div className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md">
              <div>
                <p className=" text-md font-semibold py-4">Most Recent Video</p>
                <img
                  src={`${recentVideo.snippet.thumbnails.medium.url}`}
                  alt="{channel.snippet.title}"
                  className="w-full h-52 md:h-auto rounded-md"
                />
                <p className="py-1  font-semibold">
                  {recentVideo.snippet.title}
                </p>
                <div className="flex items-center  font-light">
                  <p className="text-lg px-3 ">
                    <IoEyeOutline />
                  </p>
                  <p>Total Views</p>
                  <span className="mx-2">|</span>
                  <p className="">
                    {videoStats.viewCount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>
                <div className="flex items-center font-light ">
                  <p className="text-md px-3 ">
                    <SlLike />
                  </p>
                  <p>Total Likes</p>
                  <span className="mx-2">|</span>
                  <p className="">
                    {videoStats.likeCount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>
              </div>
              <button
                className={`w-full  bg-red-600 hover:bg-red-400 bg-[#FC0707]  mb-2 font-bold py-2 mt-3 px-4 rounded ${
                  lightMode ? "text-white" : ""
                }`}
                onClick={() => {
                  handleSeeMore(recentVideo);
                }}
              >
                See Stats
              </button>
            </div>
          )}
          {/* pie chart div */}
          {recentVideo && recentVideo.snippet && (
            <div className="w-full h-auto md:h-52 shadow-xl mt-8 bg-white  bg-opacity-25 px-4 rounded-md flex flex-col">
              <p className=" text-md font-semibold pt-4">Recent Video</p>
              <div className="flex flex-grow">
                <div className="w-40 md:w-1/2 h-full mr-2 flex flex-col  justify-center">
                  <div className="flex items-center mr-2 mb-2">
                    <div className="w-2  mr-2 h-16 bg-[#FC0707] rounded-full"></div>
                    <div className="flex flex-col">
                      <p className="text-sm font-bold">Likes</p>
                      <p>
                        {videoStats &&
                          videoStats.likeCount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center mr-2">
                    <div className="w-2 mr-2 h-16 bg-[#4ade80] rounded-full"></div>
                    <div className="flex flex-col">
                      <p className="text-sm font-bold">Comments</p>
                      <p>
                        {videoStats.commentCount &&
                          videoStats.commentCount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-60 md:w-1/2 h-full flex items-center justify-center">
                  <PieChart width={150} height={150}>
                    <Pie
                      data={data}
                      cx={75}
                      cy={75}
                      innerRadius={40}
                      outerRadius={60}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-full md:w-3/4">
          {/* cards for the 28 days stat */}
          <div className="flex flex-wrap lg:flex-nowrap">
            <div
              className={`w-full md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl mt-8 p-4 rounded-md bg-white bg-opacity-25 ${
                lightMode ? "border" : ""
              }`}
            >
              <div className="flex justify-between pt-3  items-center">
                <p className="text-lg font-semibold ">SUBSCRIBERS GAINED</p>
                <p className="text-2xl font-bold text-green-400">
                  {dailyData.length > 0 &&
                    formatNumber(calculateTotalSubscribersGained(dailyData))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm font-semibold text-stone-400">
                  {dailyData.length == 1 ? (
                    <span>TODAY</span>
                  ) : (
                    <span>LAST {dailyData.length} DAYS</span>
                  )}
                </p>
                {/* <div className="flex items-center">
                  <span className="text-orange-500 text-sm mr-2">
                    {calculateTotalSubscribersGained(dailyData) < 0 ? (
                      <span className="text-red-500">↓</span>
                    ) : calculateTotalSubscribersGained(dailyData) > 0 ? (
                      <span className="text-green-500">↑</span>
                    ) : null}
                    {formatNumber(calculateTotalSubscribersGained(dailyData))}
                  </span>
                </div> */}
              </div>
            </div>
            <div
              className={`w-full md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl mt-8 mb-4 md:mb-0 p-4 rounded-md bg-white bg-opacity-25 ${
                lightMode ? "border" : ""
              }`}
            >
              <div className="flex justify-between pt-3 items-center">
                <p className="text-lg font-semibold ">VIEWS</p>
                <p className="text-2xl font-bold text-green-400">
                  {dailyData.length > 0 &&
                    formatNumber(calculateTotalViewsGained(dailyData))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm font-semibold text-stone-400">
                  {dailyData.length == 1 ? (
                    <span>TODAY</span>
                  ) : (
                    <span>LAST {dailyData.length} DAYS</span>
                  )}
                </p>
                {/* <div className="flex items-center">
                  <span className="text-orange-500 text-sm mr-2">
                    {calculateTotalViewsGained(dailyData) < 0 ? (
                      <span className="text-red-500">↓</span>
                    ) : calculateTotalViewsGained(dailyData) > 0 ? (
                      <span className="text-green-500">↑</span>
                    ) : null}
                    {formatNumber(calculateTotalViewsGained(dailyData))}
                  </span>
                </div> */}
              </div>
            </div>
            {/* <div className="w-full md:w-1/2 lg:w-1/3 lg:mr-2 h-auto shadow-xl mt-8 p-4 rounded-md">
              <div className="flex justify-between pt-3 items-center">
                <p className="text-lg font-semibold">VIEWS</p>
                <p className="text-2xl font-bold">1.7B</p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm font-semibold text-stone-400">
                  LAST 28 DAYS
                </p>
                <div className="flex items-center">
                  <span className="text-green-500 text-sm mr-2">↑ 71.9M</span>
                </div>
              </div>
            </div> */}
          </div>
          {/* cards for the 28 days stat */}
          <div className="flex flex-wrap lg:flex-nowrap">
            <div
              className={`w-full md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl mt-3 p-4 rounded-md bg-white bg-opacity-25 ${
                lightMode ? "border" : ""
              }`}
            >
              <div className="flex justify-between pt-3  items-center">
                <p className="text-lg font-semibold ">Like Engagement Rate</p>
                <p className="text-2xl font-bold text-green-400">
                  {channelEngRates.likeEngagementRate
                    ? `${channelEngRates.likeEngagementRate} %`
                    : null}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm font-semibold text-stone-400">
                  <span>LAST 10 VIDEOS</span>
                </p>
              </div>
            </div>
            <div
              className={`w-full md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl mt-3 p-4 rounded-md bg-white bg-opacity-25 ${
                lightMode ? "border" : ""
              }`}
            >
              <div className="flex justify-between pt-3 items-center">
                <p className="text-lg font-semibold ">
                  Comment Engagement Rate
                </p>
                <p className="text-2xl font-bold text-green-400">
                  {channelEngRates.commentEngagementRate
                    ? `${channelEngRates.commentEngagementRate} %`
                    : null}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm font-semibold text-stone-400">
                  <span>LAST 10 VIDEOS</span>
                </p>
              </div>
            </div>
          </div>

          {/* Monthly chart div */}
          <div className="h-auto md:h-fill shadow-xl mt-8 pb-10 bg-white bg-opacity-10 p-4 rounded-md">
            <p className=" text-xl font-normal pl-4 py-4">Monthly Views</p>
            <p className=" text-2xl font-bold pl-4 pb-7">
              {hoveredData
                ? hoveredData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : calculateTotalViewsGained(monthlyData)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>

            <ReactECharts ref={chartRef} option={getOption("view")} />
          </div>
        </div>
      </div>
      {/* Monthly subscribers chart */}
      <div className="h-auto md:h-fill shadow-xl mt-8 pb-16 bg-white bg-opacity-10 p-4 rounded-md">
        <p className=" text-xl font-normal pl-4 py-4">Monthly Subscribers</p>
        <p className=" text-2xl font-bold pl-4 pb-7">
          {hoveredData2
            ? hoveredData2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : calculateTotalSubscribersGained(monthlyData)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>

        <ReactECharts ref={chartRef2} option={getOption("sub")} />
      </div>
      {/* the table for views and subscribers */}
      <div className="h-auto md:h-fill shadow-xl mt-8 pb-16 bg-white bg-opacity-10 p-4 rounded-md ">
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className={`${lightMode ? "" : "tableClass"} `}
          scroll={{ x: true }}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    channelEngRates: state.videoDetail.channelEngRates,
    lightMode: state.app.lightMode,
  };
};

const mapDispatchToProps = {
  getVideoStats: getVideoStats,
  getVideosData: getVideosData,
};
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
