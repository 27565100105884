import React, { useEffect } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import { getSimilarChannels } from "../../redux/similar/similarState";
import "./style.css";
import { Helmet } from "react-helmet";

const Similar = ({
  getSimilarChannels,
  data,
  similarChannels,
  lightMode,
  channelDetail,
  channelId,
}) => {
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <a
          href={`https://www.youtube.com/${record.customUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <a
          href={`https://www.youtube.com/${record.customUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: "Subscriber Count",
      dataIndex: "subscriberCount",
      key: "subscriberCount",
    },
    {
      title: "View Count",
      dataIndex: "viewCount",
      key: "viewCount",
    },
    {
      title: "Video Count",
      dataIndex: "videoCount",
      key: "videoCount",
    },
    // {
    //   title: "Similarity Score",
    //   dataIndex: "similarityScore",
    //   key: "similarityScore",
    // },
  ];
  useEffect(() => {
    getSimilarChannels(channelId);
  }, []);

  return (
    <div className="flex mt-5  justify-center">
      {channelDetail.snippet && (
        <Helmet>
          <title>
            Similar Channels | Channels Related to
            {channelDetail.snippet.customUrl}
          </title>
        </Helmet>
      )}
      <div className=" mt-5" style={{ width: "100%" }}>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          className={`p-5 px-10 ${lightMode ? "" : "tableClass"}`}
          scroll={{ x: true }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.similar,
    ...state.channel,
    lightMode: state.app.lightMode,
  };
};

const mapDispatchToProps = {
  getSimilarChannels: getSimilarChannels,
};
export default connect(mapStateToProps, mapDispatchToProps)(Similar);
