import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    searchResults: [],
    selectedChannel: [],
    selectedRegion: [],
    selectedVideo: [],
    popularChannels: [],
    regions: [],
  },
  reducers: {
    getSearchResults: (state, action) => {},
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },
    searchClick: (state, action) => {},
    regionClick: (state, action) => {},
    getPopularChannels: (state, action) => {},
    updatePopularChannels: (state, action) => {
      state.popularChannels = action.payload;
    },
    getRegions: (state, action) => {},
    updateRegions: (state, action) => {
      state.regions = action.payload;
    },
  },
});

export const {
  getSearchResults,
  setSearchResults,
  setSelectedChannel,
  setSelectedRegion,
  setSelectedVideo,
  searchClick,
  regionClick,
  getPopularChannels,
  updatePopularChannels,
  getRegions,
  updateRegions
} = homeSlice.actions;
export default homeSlice.reducer;
