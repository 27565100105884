import React from "react";
import ReactCountryFlag from "react-country-flag";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const About = ({ channelDetail, lightMode }) => {
  const formatNumber = (number) => {
    if (number < 1000) {
      return number.toString();
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else {
      return (number / 1000000000).toFixed(1) + "B";
    }
  };

  const formatPublishedDate = (publishedAt) => {
    const publishedDate = new Date(publishedAt);
    const currentDate = new Date();

    const differenceInMilliseconds = currentDate - publishedDate;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);
    const differenceInYears = Math.floor(differenceInDays / 365);

    if (differenceInYears > 0) {
      return `${differenceInYears} year${differenceInYears > 1 ? "s" : ""} ago`;
    } else if (differenceInDays > 0) {
      return `${differenceInDays} day${differenceInDays > 1 ? "s" : ""} ago`;
    } else if (differenceInHours > 0) {
      return `${differenceInHours} hour${differenceInHours > 1 ? "s" : ""} ago`;
    } else if (differenceInMinutes > 0) {
      return `${differenceInMinutes} minute${
        differenceInMinutes > 1 ? "s" : ""
      } ago`;
    } else {
      return "Just now";
    }
  };

  return (
    <div>
      {channelDetail.snippet && (
        <Helmet>
          <title>About | {channelDetail.snippet.customUrl}</title>
        </Helmet>
      )}
      <div
        className={`flex flex-wrap lg:flex-nowrap ${
          lightMode ? "text-white" : ""
        }`}
      >
        <div className="w-full md:w-1/2 lg:w-1/3 lg:mr-16 h-auto shadow-xl mt-8 p-4 border rounded-md bg-gray-600 ">
          <div className="text-center pt-3 items-center">
            <p className="text-lg font-semibold mb-3">VIEWS</p>
            <p className="text-3xl font-bold">
              {channelDetail.statistics &&
                formatNumber(channelDetail.statistics.viewCount)}
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/3 lg:mr-16 h-auto shadow-xl mt-8 p-4 border rounded-md bg-gray-600 ">
          <div className="text-center pt-3 items-center">
            <p className="text-lg font-semibold mb-3">SUBSCRIBERS</p>

            <p className="text-3xl font-bold">
              {channelDetail.statistics &&
                formatNumber(channelDetail.statistics.subscriberCount)}
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/3 lg:mr-16 h-auto shadow-xl mt-8 p-4 border rounded-md bg-gray-600 ">
          <div className="text-center pt-3 items-center">
            <p className="text-lg font-semibold mb-3">COUNTRY</p>
            <p className="text-3xl font-bold">
              {channelDetail.snippet && (
                <ReactCountryFlag
                  className="ml-4"
                  countryCode={`${channelDetail.snippet.country}`}
                  svg
                />
              )}
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/3  h-auto shadow-xl mt-8 p-4 border rounded-md bg-gray-600 ">
          <div className="text-center pt-3 items-center">
            <p className="text-lg font-semibold mb-3">CHANNEL CREATED</p>
            <p className="text-2xl font-bold">
              {channelDetail.snippet &&
                formatPublishedDate(channelDetail.snippet.publishedAt)}
            </p>
          </div>
        </div>
      </div>

      <div className="h-auto shadow-xl px-5 mt-8 lg:px-20 py-10 border rounded-md ">
        <p className="text-lg font-semibold">DESCRIPTION</p>
        <p className="pt-5 lg:pr-96">
          {channelDetail.snippet && channelDetail.snippet.description}
        </p>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    lightMode: state.app.lightMode,
  };
};

export default connect(mapStateToProps)(About);
