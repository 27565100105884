import React, { useState, useEffect } from "react";
import Topbar from "../Topbar/Topbar";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { getChannel } from "../../redux/channel/channelState";

const App = ({ getChannel, channelDetail, lightMode }) => {
  // useEffect(() => {
  //   getChannel();
  // }, []);
  return (
    <div
      className={`h-screen overflow-x-hidden ${
        lightMode ? "bg-white text-black" : "bg-black bg-opacity-90 text-white"
      }`}
    >
      <Topbar />
      <div className="px-5 lg:px-20 pt-2">
        <Outlet />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.channel,
    lightMode: state.app.lightMode,
  };
};

const mapDispatchToProps = {
  getChannel: getChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default App;
