import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    lightMode: localStorage.getItem("lightMode") || false,
  },
  reducers: {
    setMode: (state, action) => {
      state.lightMode = action.payload;
      localStorage.setItem("lightMode", action.payload); // Update mode in localStorage
    },
  },
});

export const { setMode } = appSlice.actions;
export default appSlice.reducer;
