import { createSlice } from "@reduxjs/toolkit";

export const vidDetailSlice = createSlice({
  name: "videoDetail",
  initialState: {
    data: [],
    videoStats: {},
    channelEngRates: {
      likeEngagementRate: 0,
      commentEngagementRate: 0,
    },
  },
  reducers: {
    getVideosData: (state, action) => {},
    getVideoStats: (state, action) => {},
    setVideosData: (state, action) => {
      state.data = action.payload;
    },
    setVideoStats: (state, action) => {
      state.videoStats = action.payload;
    },
    setChannelEngRates: (state, action) => {
      state.channelEngRates = action.payload;
    },
  },
});

export const {
  getVideosData,
  getVideoStats,
  setVideosData,
  setVideoStats,
  setChannelEngRates,
} = vidDetailSlice.actions;
export default vidDetailSlice.reducer;
