import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { connect } from "react-redux";
import { getRegions, getRegionVideos, startTracking } from "../../redux/videos/videoState";
import { Helmet } from "react-helmet";
import ReactCountryFlag from "react-country-flag";

const VideosRegion = ({
  getRegionVideos,
  videos,
  getRegions,
  regions,
  loading,
  pageToken,
  startTracking,
  lightMode,
}) => {
  const regionId = sessionStorage.getItem("regionId");
  const location = useLocation();
  const navigate = useNavigate();
  const [region, setRegion] = useState([]);
  const [videoRecords, setVideoRecords] = useState([]);
  const [date, setDate] = useState("");

  const bottomBoundaryRef = useRef(null);

  const handleSeeMore = (video) => {
    
    sessionStorage.setItem("channelId", video.channelId);
    sessionStorage.setItem("selectedVideo", video.videoId);
    
    startTracking();
    
    const newPath = location.pathname.includes(`/${regionId}/regionalVideos`)
    ? location.pathname.replace(`/${regionId}/regionalVideos`, "/") +
    `${video.channelCustomUrl}/videos/${video.videoId}`
    : `/${video.channelCustomUrl}/videos/${video.videoId}`;
    
    console.log(newPath);
    navigate(newPath);
  };

  useEffect(() => {
    getRegions();
    getRegionVideos();
  }, []);

  useEffect(() => {
    if (videos && videos.length > 0) {
      setVideoRecords(videos);
    }
  }, [videos]);

  const handleIntersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting && pageToken) {
      getRegionVideos(pageToken);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });

    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current);
    }

    return () => {
      if (bottomBoundaryRef.current) {
        observer.unobserve(bottomBoundaryRef.current);
      }
    };
  }, [pageToken]);

  useEffect(() => {
    if (regions && regions.length > 0) {
      const regionDetails = regions.find((region) => region.id === regionId);

      setRegion(regionDetails);
    }
  }, [regions]);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const filterVideos = () => {
    if (!date) return setVideoRecords(videos); // If no date is selected, show all videos
    const formattedDate = formatDate(date);
    setVideoRecords([]);

    // Filter videos where the publish date is equal to or after the selected date
    const filteredDate = videos.filter((video) => {
      return video.publishedAt >= formattedDate;
    });

    setVideoRecords(filteredDate);
  };

  useEffect(() => {
    filterVideos();
  }, [date]);

  // Handle the change event for the input
  const handleDateChange = (e) => {
    setDate(e.target.value); // Update the state with the selected date
  };

  return (
    <div className="mb-5 mt-10 ">
      <div className="shadow-xl bg-gray-500 rounded-md md:h-56 mt-5 text-white">
        <div className="flex flex-col items-center gap-4 w-[100%] pt-12">
          <p
            className={`text-3xl lg:text-5xl font-semibold text-center text-white`}
          >
            Trending Videos
          </p>
        </div>

        <div className="w-[100%] flex justify-center pt-5">
          <div className="flex flex-col items-center gap-4 w-[50%]">
            {region && (
              <p className="text-3xl font-semibold flex items-center gap-1">
                {region.title}
                <ReactCountryFlag
                  className="ml-3"
                  countryCode={`${region.id}`}
                  svg
                />
              </p>
            )}
          </div>
          <div className="flex items-center justify-center gap-4 w-[50%]">
            <label htmlFor="date" className="text-lg font-medium text-white">
              Select Date
            </label>
            <input
              id="date"
              className="form-input bg-white text-black rounded-lg border-2 border-gray-300 p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-[30%]"
              name="date"
              type="date"
              value={date}
              onChange={handleDateChange}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
        {region && (
          <Helmet>
            <title> {region.title || "Region"} | All Videos</title>
          </Helmet>
        )}

        {videoRecords.map((video, index) => (
          <div
            key={index}
            className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md "
          >
            <div>
              <img
                src={video.thumbnails.medium.url}
                alt={video.title}
                className="w-full h-52 md:h-auto rounded-md"
              />
              <p className="py-1 font-semibold">{video.title}</p>
              <div className="flex items-center font-light">
                <p>Channel</p>
                <span className="mx-2">|</span>
                <p
                  // onClick={() => searchClick(video.channelId)}
                  className=""
                >
                  {video.channelTitle}
                </p>
              </div>
              <div className="flex items-center font-light">
                <p>Total Views</p>
                <span className="mx-2">|</span>
                <p className="">
                  {video.statistics.viewCount
                    ? video.statistics.viewCount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </p>
              </div>
              <div className="flex items-center font-light">
                <p>Total Likes</p>
                <span className="mx-2">|</span>
                <p className="">
                  {video.statistics.likeCount
                    ? video.statistics.likeCount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </p>
              </div>
              <div className="flex items-center font-light">
                <p>Upload Date</p>
                <span className="mx-2">|</span>
                <p className="">{video.publishedAt}</p>
              </div>
            </div>
            <button
              className={`w-full bg-[#FC0707] hover:bg-gray-500  mb-2 font-bold py-2 mt-3 px-4 rounded ${
                lightMode ? "text-white" : ""
              }`}
              onClick={() => {
                handleSeeMore(video);
              }}
            >
              See Stats
            </button>
          </div>
        ))}

        {loading && (
          <div className="w-full h-auto md:h-fit shadow-xl mt-8 bg-white bg-opacity-25 px-4 rounded-md  flex justify-center items-center">
            <FaSpinner className="animate-spin " />
            <p className="ml-2">Loading...</p>
          </div>
        )}
        {/* <div ref={bottomBoundaryRef}></div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.videos,
    lightMode: state.app.lightMode,
  };
};

const mapDispatchToProps = {
  getRegionVideos: getRegionVideos,
  getRegions: getRegions,
  startTracking: startTracking,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideosRegion);
