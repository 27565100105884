import { put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import {
  setVideosData,
  setVideoStats,
  setChannelEngRates,
} from "./videoDetailState";

const API_KEY = "AIzaSyB7MaPvK4aqh9xXe1I4RHm9HMTx-_gBTzM";

// Old key
// const API_KEY = "AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw";
function* fetchLatestVideoStats() {
  try {
    const channelId = sessionStorage.getItem("channelId");
    if (!channelId) {
      console.error("ChannelId not found in sessionStorage");
      return;
    }

    const channelResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${channelId}&key=${API_KEY}`
    );
    const playlistId =
      channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads;

    const playlistItemsResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=10&key=${API_KEY}`
    );
    const videoIds = playlistItemsResponse.data.items.map(
      (item) => item.snippet.resourceId.videoId
    );

    const videoStatsPromises = videoIds.map((videoId) =>
      axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoId}&key=${API_KEY}`
      )
    );
    const videoStatsResponses = yield Promise.all(videoStatsPromises);

    const videosData = videoStatsResponses.map((response) => {
      const video = response.data.items[0];
      return {
        key: video.id,
        videoTitle: video.snippet.title,
        date: new Date(video.snippet.publishedAt).toLocaleDateString(),
        likes: video.statistics.likeCount,
        views: video.statistics.viewCount,
        comments: video.statistics.commentCount
          ? video.statistics.commentCount
          : null,
      };
    });

    const totalLikes = videosData.reduce(
      (acc, video) => acc + parseInt(video.likes),
      0
    );
    const totalViews = videosData.reduce(
      (acc, video) => acc + parseInt(video.views),
      0
    );
    const totalComments = videosData.reduce(
      (acc, video) => (video.comments ? acc + parseInt(video.comments) : acc),
      0
    );

    const likeEngagementRate = ((totalLikes / totalViews) * 100).toFixed(2);
    const commentEngagementRate = ((totalComments / totalViews) * 100).toFixed(
      2
    );
    yield put(
      setChannelEngRates({
        likeEngagementRate,
        commentEngagementRate,
      })
    );

    yield put(setVideosData(videosData));
  } catch (error) {
    console.error("Error fetching latest video statistics:", error);
  }
}

function* getVideoStat() {
  try {
    const videoId = sessionStorage.getItem("selectedVideo");
    const videoStatResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics,contentDetails,topicDetails&id=${videoId}&key=${API_KEY}`
    );

    const videoStats = videoStatResponse.data.items[0];

// Fetch top comments
let topComments = [];
try {
  const commentsResponse = yield axios.get(
    `https://www.googleapis.com/youtube/v3/commentThreads?part=snippet&videoId=${videoId}&key=${API_KEY}&order=relevance&maxResults=10`
  );

  if (commentsResponse.data.items && commentsResponse.data.items.length > 0) {
    topComments = commentsResponse.data.items.map((comment) => ({
      textDisplay: comment.snippet.topLevelComment.snippet.textDisplay,
      authorDisplayName: comment.snippet.topLevelComment.snippet.authorDisplayName,
      authorProfileImageUrl: comment.snippet.topLevelComment.snippet.authorProfileImageUrl,
      likeCount: comment.snippet.topLevelComment.snippet.likeCount,
      date: new Date(comment.snippet.topLevelComment.snippet.publishedAt).toLocaleDateString(),
    }));
  }
} catch (error) {
  // Check if the error is related to comments being disabled
  if (error.response?.data?.error?.message?.includes("has disabled comments")) {
    console.warn("Comments are disabled for this video.");
  } else {
    console.error("Error fetching comments:", error.response?.data || error.message);
  }
}

// Append top comments to the videoStats object
videoStats.topComments = topComments;

    yield put(setVideoStats(videoStats));
  } catch (error) {
    console.error("Error fetching video statistics:", error);
  }
}
export default function* rootSaga() {
  yield all([takeEvery("videoDetail/getVideosData", getVideoStat)]);
  yield all([takeEvery("videoDetail/getVideoStats", fetchLatestVideoStats)]);
  //   yield all([takeEvery("channel/startTracking", workStartTracking)]);
}
