import { createSlice } from "@reduxjs/toolkit";

export const topbarSlice = createSlice({
  name: "topbar",
  initialState: {
    search_results: [],
    search_click: [],
  },
  reducers: {
    getSearchResults: (state, action) => {},
    getSearchSuccess: (state, action) => {
      state.search_results = action.payload;
    },
    searchClick: (state, action) => {},
    updateSearchClick: (state, action) => {
      state.search_click = action.payload;
    },
  },
});

export const {
  getSearchResults,
  getSearchSuccess,
  searchClick,
  updateSearchClick,
} = topbarSlice.actions;
export default topbarSlice.reducer;
