import { put, takeEvery, all } from "redux-saga/effects";
import axios from "axios";
import {
  getChannelSuccess,
  getVideoStatsSuccess,
  getVideoSuccess,
  getDailyData,
  getMonthlyData,
  getTracked,
  getTrackedChannel,
  getChannel,
  updateBackToHome,
} from "./channelState.js";
import { ConstructionRounded } from "@mui/icons-material";

function* workGetChannel(action) {
  const channelid = sessionStorage.getItem("channelId");

  let response;
  try {
    if (channelid) {
      const testResponse = yield axios.get(
        `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics,contentDetails&id=${channelid}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
      );

      if (testResponse.data.items[0].snippet.customUrl === action.payload) {
        response = testResponse;
      } else {
        if (action.payload) {
          const getChannelByUrl = yield axios.get(
            `https://tubestats.io/api/channels/customURL/${action.payload}`
          );

          sessionStorage.setItem(
            "channelId",
            getChannelByUrl.data.data.channelId
          );
          window.location.reload();
          yield put(getTrackedChannel());

          response = yield axios.get(
            `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics,contentDetails&id=${getChannelByUrl.data.data.channelId}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
          );
        }
      }
    } else {
      try {
        const getChannelByUrl = yield axios.get(
          `https://tubestats.io/api/channels/customURL/${action.payload}`
        );

        sessionStorage.setItem(
          "channelId",
          getChannelByUrl.data.data.channelId
        );

        yield put(getTrackedChannel());

        response = yield axios.get(
          `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics,contentDetails&id=${getChannelByUrl.data.data.channelId}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
        );
      } catch {
        console.log("was here");
        yield put(updateBackToHome(true));
        return; // Exit early to prevent further execution
      }
    }
    const videoResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${response.data.items[0].contentDetails.relatedPlaylists.uploads}&maxResults=1&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
    );

    const videoStatResponse = yield axios.get(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoResponse.data.items[0].snippet.resourceId.videoId}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
    );

    const video = videoResponse.data.items[0];
    const videoStat = videoStatResponse.data.items[0].statistics;

    yield put(getChannelSuccess(response.data.items[0]));
    yield put(getVideoSuccess(video));
    yield put(getVideoStatsSuccess(videoStat));
  } catch (error) {
    console.log(error);
  }
}

function* workGetTrackedChannel(action) {
  const channelid = sessionStorage.getItem("channelId");
  try {
    const trackResponse = yield axios.get(
      `https://tubestats.io/api/channels/${channelid}`
    );

    yield put(getDailyData(trackResponse.data.data.dailyValues));
    yield put(getMonthlyData(trackResponse.data.data.monthlyValues));
    yield put(getTracked(true));
  } catch (error) {
    console.log(error);
    yield put(getTracked(false));
  }
}

function* workStartTracking(action) {
  const id = sessionStorage.getItem("channelId");
  try {
    const response = yield axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${id}&key=AIzaSyAz9IqjC4LmxPplgFSzEHVCaEUf3r6fedw`
    );

    yield axios.post(`https://tubestats.io/api/track`, {
      channelId: id,
      customURL: response.data.items[0].snippet.customUrl,
      channelTitle: response.data.items[0].snippet.title,
      initialViews: response.data.items[0].statistics.viewCount,
      initialSubscribers: response.data.items[0].statistics.subscriberCount,
    });
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery("channel/getChannel", workGetChannel)]);
  yield all([takeEvery("channel/getTrackedChannel", workGetTrackedChannel)]);
  yield all([takeEvery("channel/startTracking", workStartTracking)]);
}
