import { createSlice } from "@reduxjs/toolkit";

export const predictionSlice = createSlice({
  name: "predictions",
  initialState: {
    dataSource: null,
    weeklyPrediction: null,
    errorMessage: false,
  },
  reducers: {
    getPredictions: (state, action) => {},
    updatePredictions: (state, action) => {
      state.dataSource = action.payload.dataSource;
      state.weeklyPrediction = action.payload.weeklyData;
    },
    predictionError: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { getPredictions, updatePredictions, predictionError } =
  predictionSlice.actions;
export default predictionSlice.reducer;
