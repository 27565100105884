// import axios from "axios";
// import { jwtDecode } from "jwt-decode";

// // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.headers.post["Content-Type"] = "application/json";

// axios.interceptors.request.use(
//   async (config) => {
//     let token = localStorage.getItem("clientToken");

//     if (token) {
//       const { exp } = jwtDecode(token);
//       if (Date.now() >= exp * 1000) {
//         localStorage.removeItem("clientToken");
//         window.location.href = "/login";
//         return Promise.reject("Token expired");
//       }
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     console.log(config);
//     return config;
//   },
//   (error) => {
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

// export default axios;

export const channelsTopicsList = [
    { value: "/m/04rlf", label: "Music", parent: true },
    { value: "/m/02mscn", label: "Christian music" },
    { value: "/m/0ggq0m", label: "Classical music" },
    { value: "/m/01lyv", label: "Country" },
    { value: "/m/02lkt", label: "Electronic music" },
    { value: "/m/0glt670", label: "Hip hop music" },
    { value: "/m/05rwpb", label: "Independent music" },
    { value: "/m/03_d0", label: "Jazz" },
    { value: "/m/028sqc", label: "Music of Asia" },
    { value: "/m/0g293", label: "Music of Latin America" },
    { value: "/m/064t9", label: "Pop music" },
    { value: "/m/06cqb", label: "Reggae" },
    { value: "/m/06j6l", label: "Rhythm and blues" },
    { value: "/m/06by7", label: "Rock music" },
    { value: "/m/0gywn", label: "Soul music" },
    { value: "/m/0bzvm2", label: "Gaming", parent: true },
    { value: "/m/025zzc", label: "Action game" },
    { value: "/m/02ntfj", label: "Action-adventure game" },
    { value: "/m/0b1vjn", label: "Casual game" },
    { value: "/m/02hygl", label: "Music video game" },
    { value: "/m/04q1x3q", label: "Puzzle video game" },
    { value: "/m/01sjng", label: "Racing video game" },
    { value: "/m/0403l3g", label: "Role-playing video game" },
    { value: "/m/021bp2", label: "Simulation video game" },
    { value: "/m/022dc6", label: "Sports game" },
    { value: "/m/03hf_rm", label: "Strategy video game" },
    { value: "/m/06ntj", label: "Sports", parent: true },
    { value: "/m/0jm_", label: "American football" },
    { value: "/m/018jz", label: "Baseball" },
    { value: "/m/018w8", label: "Basketball" },
    { value: "/m/01cgz", label: "Boxing" },
    { value: "/m/09xp_", label: "Cricket" },
    { value: "/m/02vx4", label: "Football" },
    { value: "/m/037hz", label: "Golf" },
    { value: "/m/03tmr", label: "Ice hockey" },
    { value: "/m/01h7lh", label: "Mixed martial arts" },
    { value: "/m/0410tth", label: "Motorsport" },
    { value: "/m/07bs0", label: "Tennis" },
    { value: "/m/07_53", label: "Volleyball" },
    { value: "/m/02jjt", label: "Entertainment", parent: true },
    { value: "/m/09kqc", label: "Humor" },
    { value: "/m/02vxn", label: "Movies" },
    { value: "/m/05qjc", label: "Performing arts" },
    { value: "/m/066wd", label: "Professional wrestling" },
    { value: "/m/0f2f9", label: "TV shows" },
    { value: "/m/019_rr", label: "Lifestyle", parent: true },
    { value: "/m/032tl", label: "Fashion" },
    { value: "/m/027x7n", label: "Fitness" },
    { value: "/m/02wbm", label: "Food" },
    { value: "/m/03glg", label: "Hobby" },
    { value: "/m/068hy", label: "Pets" },
    { value: "/m/041xxh", label: "Physical attractiveness [Beauty]" },
    { value: "/m/07c1v", label: "Technology" },
    { value: "/m/07bxq", label: "Tourism" },
    { value: "/m/07yv9", label: "Vehicles" },
    { value: "/m/098wr", label: "Society", parent: true },
    { value: "/m/09s1f", label: "Business" },
    { value: "/m/0kt51", label: "Health" },
    { value: "/m/01h6rj", label: "Military" },
    { value: "/m/05qt0", label: "Politics" },
    { value: "/m/06bvp", label: "Religion" },
    { value: "/m/01k8wb", label: "Knowledge" }
];