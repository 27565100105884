import { all } from "redux-saga/effects";
import channel from "./channel/saga";
import topbar from "./topbar/saga";
import similar from "./similar/saga";
import videos from "./videos/saga";
import home from "./home/saga";
import vidDetail from "./videoDetail/saga";
import prediction from "./predictions/saga";

export default function* rootSaga() {
  yield all([
    channel(),
    topbar(),
    similar(),
    videos(),
    home(),
    vidDetail(),
    prediction(),
  ]);
}
