import { createSlice } from "@reduxjs/toolkit";

export const channelSlice = createSlice({
  name: "channel",
  initialState: {
    channelId: sessionStorage.getItem("channelId") || null,
    channelDetail: {},
    recentVideo: {},
    videoStats: {},
    customUrl: "",
    dailyData: [],
    monthlyData: [],
    tracked: false,
    backToHome: false,
  },
  reducers: {
    updateChannelId: (state, action) => {
      sessionStorage.setItem("channelId", action.payload);
      state.channelId = action.payload;
    },
    updateBackToHome: (state, action) => {
      state.backToHome = action.payload;
    },
    getChannel: (state, action) => {},
    getTrackedChannel: (state, action) => {},
    startTracking: (state) => {},
    getChannelSuccess: (state, action) => {
      state.channelDetail = action.payload;
    },
    getVideoSuccess: (state, action) => {
      state.recentVideo = action.payload;
    },
    getVideoStatsSuccess: (state, action) => {
      state.videoStats = action.payload;
    },
    getDailyData: (state, action) => {
      state.dailyData = action.payload;
    },
    getMonthlyData: (state, action) => {
      state.monthlyData = action.payload;
    },
    getTracked: (state, action) => {
      state.tracked = action.payload;
    },
  },
});

export const {
  getChannel,
  getChannelSuccess,
  getVideoSuccess,
  getVideoStatsSuccess,
  getTrackedChannel,
  getDailyData,
  getMonthlyData,
  getTracked,
  startTracking,
  updateChannelId,
  updateBackToHome,
} = channelSlice.actions;
export default channelSlice.reducer;
