import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import { connect } from "react-redux";
import "./style.css";
import {
  getPredictions,
  updatePredictions,
} from "../../redux/predictions/predictionState";
import ReactECharts from "echarts-for-react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const Predictions = ({
  weeklyPrediction,
  lightMode,
  getPredictions,
  dataSource,
  channelDetail,
  errorMessage,
  errorText,
  backToHome,
  updatePredictions,
}) => {
  const chartRef = useRef(null);
  const chartRef2 = useRef(null);
  const [hoveredData2, setHoveredData2] = useState(null);
  const [hoveredData, setHoveredData] = useState(null);
  const [months, setMonths] = useState([]);
  const [viewDataPoints, setViewDataPoints] = useState([]);
  const [subDataPoints, setSubDataPoints] = useState([]);
  const { channelTitle } = useParams();

  useEffect(() => {
    getPredictions(channelTitle);
  }, []);

  useEffect(() => {
    if (dataSource != null) {
      const updatedViewDataPoints = dataSource.map(
        (item) => item.viewPrediction
      );
      const updatedSubDataPoints = dataSource.map(
        (item) => item.subscriberPrediction
      );
      setViewDataPoints(updatedViewDataPoints);
      setSubDataPoints(updatedSubDataPoints);

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const updatedMonths = dataSource.map((item) => {
        const date = new Date(item.goalDate); // Convert the date string to a Date object
        const monthIndex = date.getMonth(); // Get the month index (0-indexed)
        const year = date.getFullYear(); // Get the year

        // Create a formatted string with the month name and year
        const formattedMonth = `${monthNames[monthIndex]}/${year}`;

        return formattedMonth;
      });

      setMonths(updatedMonths);
    }
  }, [dataSource]);
  useEffect(() => {
    if (dataSource != null && !errorMessage) {
      const chartInstance = chartRef.current.getEchartsInstance();
      const chartInstance2 = chartRef2.current.getEchartsInstance();
      if (chartInstance) {
        chartInstance.on("mouseover", handleMouseOver);
        chartInstance.on("mouseout", handleMouseOut); // Add mouseout event listener
      }

      if (chartInstance2) {
        chartInstance2.on("mouseover", handleMouseOver2);
        chartInstance2.on("mouseout", handleMouseOut2);
      }
      return () => {
        if (chartInstance) {
          chartInstance.off("mouseover", handleMouseOver);
          chartInstance.off("mouseout", handleMouseOut); // Remove mouseout event listener
        }
        if (chartInstance2) {
          chartInstance2.off("mouseover", handleMouseOver2);
          chartInstance2.off("mouseout", handleMouseOut2);
        }
      };
    }
  }, []);
  const calculateTotalSubscribersGained = (values) => {
    // Ensure there's enough data to calculate
    if (values.length < 2) {
      return 0;
    }

    // Get the subscriber count at the start
    let subscribersAtStart = values[0].subscriberPrediction;
    // Get the subscriber count at the end
    let subscribersAtEnd = values[values.length - 1].subscriberPrediction;

    // Calculate subscribers gained
    let subscribersGained = subscribersAtEnd - subscribersAtStart;

    return subscribersGained;
  };
  const calculateTotalViewsGained = (values) => {
    // Ensure there's enough data to calculate
    if (values.length < 2) {
      return 0;
    }

    // Get the subscriber count at the start
    let viewsAtStart = values[0].viewPrediction;
    // Get the subscriber count at the end
    let viewsAtEnd = values[values.length - 1].viewPrediction;

    // Calculate views gained
    let viewsGained = viewsAtEnd - viewsAtStart;

    return viewsGained;
  };
  const formatNumber = (number) => {
    if (number < 1000) {
      return number.toString();
    } else if (number < 1000000) {
      return (number / 1000).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] + "K";
    } else if (number < 1000000000) {
      return (
        (number / 1000000).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] + "M"
      );
    } else if (number < 1000000000000) {
      return (
        (number / 1000000000).toString().match(/^-?\d+(?:\.\d{0,1})?/)[0] + "B"
      );
    }
  };
  const getTimeUntil = (dateString) => {
    const currentDate = new Date();
    const targetDate = new Date(dateString);

    const timeDiff = targetDate.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const years = Math.floor(daysDiff / 365);
    const months = Math.floor((daysDiff % 365) / 30);

    let result = "";
    if (years > 0) {
      result += `${years} year${years > 1 ? "s" : ""} `;
    }
    if (months > 0) {
      result += `${months} month${months > 1 ? "s" : ""}`;
    }

    return result.trim() || "Today";
  };
  const columns = [
    {
      title: "Goal Date",
      dataIndex: "goalDate",
      key: "goalDate",
    },
    {
      title: "Time Until",
      dataIndex: "timeUntil",
      key: "timeUntil",
      render: (text, record) => getTimeUntil(record.goalDate),
    },
    {
      title: "Subscribers Prediction",
      dataIndex: "subscriberPrediction",
      key: "subscriberPrediction",
      render: (text, record) => <span>{`${text.toLocaleString()} `}</span>,
    },
    {
      title: "Views Prediction",
      dataIndex: "viewPrediction",
      key: "viewPrediction",
      render: (text, record) => <span>{`${text.toLocaleString()} `}</span>,
    },
  ];
  const SubscriberPrediction = ({
    currentSubscribers,
    predictedSubscribers,
  }) => {
    const currentSubscribersInt = parseInt(currentSubscribers);
    const predictedSubscribersInt = parseInt(predictedSubscribers);

    // Calculate subscriber increment
    const subscriberIncrement = predictedSubscribersInt - currentSubscribersInt;

    // Calculate percentage change
    const percentageChange = (
      (subscriberIncrement / currentSubscribersInt) *
      100
    ).toFixed(2);

    return { subscriberIncrement, percentageChange };
  };
  const ViewPrediction = ({ currentViews, predictedViews }) => {
    // Convert subscriber counts from strings to integers
    const currentViewsInt = parseInt(currentViews);
    const predictedViewsInt = parseInt(predictedViews);
    // Calculate subscriber increment
    const viewIncrement = predictedViewsInt - currentViewsInt;

    // Calculate percentage change
    const percentageChange = ((viewIncrement / currentViewsInt) * 100).toFixed(
      2
    );

    return { viewIncrement, percentageChange };
  };
  const getOption = (dataType) => {
    const dataPoints = dataType === "view" ? viewDataPoints : subDataPoints;
    const largestNumber = Math.max(...dataPoints);
    const roundedNumber = Math.ceil(largestNumber / 1000000000) * 1000000000;
    const yaxisNum = roundedNumber;
    return {
      grid: {
        left: "10%",
        right: "10%",
        bottom: "10%",
        top: "10%",
      },
      xAxis: {
        type: "category",
        data: months.slice(0, 12),
        axisLine: {
          lineStyle: {
            color: `${lightMode ? "black" : "#fff"}`,
          },
        },
      },
      yAxis: {
        type: "value",
        max: yaxisNum,
        axisLabel: {
          formatter: (value) => formatNumber(value),
          color: `${lightMode ? "black" : "#fff"}`,
        },
        axisLine: {
          lineStyle: {
            color: "#ccc",
          },
        },
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) => params[0].value.toLocaleString(),
      },
      series: [
        {
          type: "line",
          data: dataPoints.slice(0, 12),
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: `${
                    lightMode
                      ? "rgba(220, 38, 38, 0.7)"
                      : "rgba(255, 255, 255, 0.8)"
                  }`,
                },
                {
                  offset: 1,
                  color: `${lightMode ? "white" : "rgba(0, 0, 0, 0.2)"}`,
                },
              ],
            },
          },
          lineStyle: {
            color: `${lightMode ? "#dc2626" : "white"}`,
          },
          symbol: "circle",
          color: "#FC0707",
          symbolSize: 8,
        },
      ],
      custom: (event) => {},
    };
  };
  const handleMouseOver = (event) => {
    setHoveredData(event.value); // Example: Update state with hovered data
  };

  const handleMouseOut = () => {
    setHoveredData(null); // Reset hoveredData to null when user stops hovering over the chart
  };

  const handleMouseOver2 = (event) => {
    setHoveredData2(event.data);
  };

  const handleMouseOut2 = () => {
    setHoveredData2(null);
  };
  return (
    <div>
      {channelDetail.snippet && (
        <Helmet>
          <title>
            Channel Predictions | Future Subscribers & Views for
            {channelDetail.snippet.customUrl}
          </title>
        </Helmet>
      )}
      {!errorMessage ? (
        dataSource != null &&
        weeklyPrediction != null && (
          <div className="w-full ">
            {/* cards for the 28 days stat */}
            <div className="flex flex-wrap lg:flex-nowrap">
              <div
                className={`w-full md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl mt-8 p-4 rounded-md bg-white bg-opacity-25 ${
                  lightMode ? "border" : ""
                }`}
              >
                <div className="flex justify-between pt-3  items-center">
                  <p className="text-lg font-semibold ">SUBSCRIBERS</p>
                  <p className="text-2xl font-bold text-green-400">
                    {formatNumber(weeklyPrediction.subPrediction)}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-sm font-semibold text-stone-400">
                    IN 7 DAYS
                  </p>

                  {channelDetail.statistics && (
                    <div className="flex items-center">
                      <span
                        className={`text-sm font-semibold mr-2 ${
                          SubscriberPrediction({
                            currentSubscribers:
                              channelDetail.statistics.subscriberCount,
                            predictedSubscribers:
                              weeklyPrediction.subPrediction,
                          }).subscriberIncrement > 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {SubscriberPrediction({
                          currentSubscribers:
                            channelDetail.statistics.subscriberCount,
                          predictedSubscribers: weeklyPrediction.subPrediction,
                        }).subscriberIncrement < 0 ? (
                          <span className="text-red-600 px-1">↓</span>
                        ) : SubscriberPrediction({
                            currentSubscribers:
                              channelDetail.statistics.subscriberCount,
                            predictedSubscribers:
                              weeklyPrediction.subPrediction,
                          }).subscriberIncrement > 0 ? (
                          <span className="text-green-500 px-1">↑</span>
                        ) : null}
                        {SubscriberPrediction({
                          currentSubscribers:
                            channelDetail.statistics.subscriberCount,
                          predictedSubscribers: weeklyPrediction.subPrediction,
                        }).subscriberIncrement.toLocaleString()}
                        <span
                          className={`px-2  ${
                            lightMode ? "text-black" : "text-white"
                          }`}
                        >
                          |
                        </span>
                        {
                          SubscriberPrediction({
                            currentSubscribers:
                              channelDetail.statistics.subscriberCount,
                            predictedSubscribers:
                              weeklyPrediction.subPrediction,
                          }).percentageChange
                        }{" "}
                        %
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`w-full md:w-1/2 lg:w-1/2 lg:mr-2 h-auto shadow-xl mt-8 p-4 rounded-md bg-white bg-opacity-25 ${
                  lightMode ? "border" : ""
                }`}
              >
                <div className="flex justify-between pt-3 items-center">
                  <p className="text-lg font-semibold ">VIEWS</p>
                  <p className="text-2xl font-bold text-green-400">
                    {" "}
                    {formatNumber(weeklyPrediction.viewsPrediction)}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-sm font-semibold text-stone-400">
                    IN 7 DAYS
                  </p>

                  {channelDetail.statistics && (
                    <div className="flex items-center">
                      <span
                        className={`text-sm font-semibold mr-2 ${
                          ViewPrediction({
                            currentViews: channelDetail.statistics.viewCount,
                            predictedViews: weeklyPrediction.viewsPrediction,
                          }).viewIncrement > 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {ViewPrediction({
                          currentViews: channelDetail.statistics.viewCount,
                          predictedViews: weeklyPrediction.viewsPrediction,
                        }).viewIncrement < 0 ? (
                          <span className="text-red-600 px-1">↓</span>
                        ) : ViewPrediction({
                            currentViews: channelDetail.statistics.viewCount,
                            predictedViews: weeklyPrediction.viewsPrediction,
                          }).viewIncrement > 0 ? (
                          <span className="text-green-500 px-1">↑</span>
                        ) : null}
                        {ViewPrediction({
                          currentViews: channelDetail.statistics.viewCount,
                          predictedViews: weeklyPrediction.viewsPrediction,
                        }).viewIncrement.toLocaleString()}
                        <span
                          className={`px-2  ${
                            lightMode ? "text-black" : "text-white"
                          }`}
                        >
                          |
                        </span>
                        {
                          ViewPrediction({
                            currentViews: channelDetail.statistics.viewCount,
                            predictedViews: weeklyPrediction.viewsPrediction,
                          }).percentageChange
                        }{" "}
                        %
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* prediction view chart */}
            <div className="h-auto md:h-fill shadow-xl mt-8 pb-10 bg-white bg-opacity-10 p-4 rounded-md">
              <p className=" text-xl font-normal pl-4 py-4">Views Prediction</p>
              <p className=" text-2xl font-bold pl-4 pb-7">
                {hoveredData
                  ? hoveredData.toLocaleString()
                  : calculateTotalViewsGained(dataSource).toLocaleString()}
              </p>

              <ReactECharts ref={chartRef} option={getOption("view")} />
            </div>
            {/* prediction subscribers chart */}
            <div className="h-auto md:h-fill shadow-xl mt-8 pb-16 bg-white bg-opacity-10 p-4 rounded-md">
              <p className=" text-xl font-normal pl-4 py-4">
                Subscribers Prediction
              </p>
              <p className=" text-2xl font-bold pl-4 pb-7">
                {hoveredData2
                  ? hoveredData2.toLocaleString()
                  : calculateTotalSubscribersGained(dataSource)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>

              <ReactECharts ref={chartRef2} option={getOption("sub")} />
            </div>
            <div className="h-auto md:h-fill shadow-xl mt-8 pb-16 bg-white bg-opacity-10 p-4 rounded-md ">
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                className={`${lightMode ? "" : "tableClass"} `}
                scroll={{ x: true }}
              />
            </div>
          </div>
        )
      ) : (
        <div className="flex justify-center items-center mt-5">
          <div>Insufficient data available for predictions.</div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    lightMode: state.app.lightMode,
    ...state.predictions,
  };
};

const mapDispatchToProps = {
  getPredictions: getPredictions,
  updatePredictions: updatePredictions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Predictions);
